<template>
  <div id="order-requiring-attention-component">
    <review v-if="currentField === 'review'" />
    <item-requiring-attention v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrderRequiringAttention',
  components: {
    ItemRequiringAttention: () => import('@/components/StagelineV2/schemaForm/online/ItemRequiringAttention'),
    Review:                 () => import('@/components/StagelineV2/schemaForm/online/Review'),
  },
  data () {
    return {
      loaded: true,
      heading: null,
      description: null,
    }
  },
  computed: {
    ...mapGetters('stageline', [
      'jurisdiction',
      'currentField',
    ]),
  },
}
</script>
